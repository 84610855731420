import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

function HomePage() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (code && state) {
      axios.post('https://v2.api.vup.news/api/state', {
        code,
        state
      })
      .then(response => {
        const { code, url, msg } = response.data;
        if (code === 0) {
          // 使用window.location.href来进行重定向
          window.location.href = url;
        } else if (code === 1) {
          // 显示错误消息
          setMessage(msg);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('请求处理失败');
      });
    }
  }, [searchParams]);

  return (
    <div className="bg-slate-100 min-h-screen flex justify-center items-center">
      {message && (
        <div className="p-4 bg-red-100 text-red-800 rounded-lg">
          {message}
        </div>
      )}
    </div>
  );
}

export default HomePage;
